






















































































import Vue from "vue";
import { CollectionEnum } from "@/types/enum/CollectionEnum";
import searchApi from "@/services/SearchApi";
import { VisualizationInfo } from "@/store/visualization-module";
import { Image } from "@/types/Publication";

let vInfo = {} as VisualizationInfo;

export default Vue.extend({
  name: "MediaDisplay",
  data() {
    return {
      imageLoading: false,
      CollectionEnum,
      pageIndex: -1,
      contentUrl: "",
      downloadUrl: "",
      nextEnabled: true,
      previousEnabled: true,
      image: {} as Image,
    };
  },
  props: {
    fullContentLoading: { type: Boolean },
    downloading: { type: Boolean },
  },
  beforeCreate() {
    vInfo = this.$store.state.visualization;
  },
  created() {
    this.reset();
    this.$store.watch(
      () => vInfo.publication,
      () => {
        this.reset();
      }
    );
  },
  computed: {
    publication: () => vInfo.publication,
    pageIndexLabel(): number {
      return this.isOnline ? this.pageIndex + 1 : this.pageIndex;
    },
    previousBtnDisabled(): boolean {
      let disabled =
        this.isFlip || this.isOfJournal
          ? this.pageIndex <= 1
          : this.pageIndex < 1;

      return disabled || !this.previousEnabled;
    },
    nextBtnDisabled(): boolean {
      let disabled;
      if (this.isFlip || this.isOfJournal) {
        disabled =
          this.pageIndex == -1 ||
          (vInfo.flipPagination && !vInfo.flipPagination[this.pageIndex + 1]);
      } else {
        disabled =
          this.pageIndex == -1 ||
          (this.publication.imagens &&
            this.pageIndex >= this.publication.imagens.length - 1);
      }

      return disabled || !this.nextEnabled;
    },
    showDownloadBtn(): boolean {
      return !this.fullContentLoading && !this.isOnline && !this.isVideo;
    },
    displayForOnlinePublication(): boolean {
      return (
        this.isOnline &&
        this.publication.imagens &&
        this.publication.imagens.length > 0
      );
    },
    isTV(): boolean {
      return this.publication.collection === CollectionEnum.TV;
    },
    isVideo(): boolean {
      return this.publication.collection === CollectionEnum.VIDEO;
    },
    isRadio(): boolean {
      return this.publication.collection === CollectionEnum.RADIO;
    },
    isFlip(): boolean {
      return this.publication.collection === CollectionEnum.FLIP;
    },
    isOfJournal(): boolean {
      return this.publication.collection === CollectionEnum.OFFICIAL_JOURNAL;
    },
    isOnline(): boolean {
      return this.publication.collection === CollectionEnum.ONLINE;
    },
    allowNavigation(): boolean {
      //allow navigation only if content is not restricted
      return this.showDownloadBtn && this.downloadUrl !== null;
    },
  },
  methods: {
    reset() {
      this.previousEnabled = true;
      this.nextEnabled = true;
      this.downloadUrl = this.publication.downloadUrl;
      if (this.isOnline) {
        if (this.publication.imagens && this.publication.imagens[0]) {
          this.image = this.publication.imagens[0];
          this.contentUrl = this.image.url;
          this.pageIndex = 0;
        } else {
          this.image = {} as Image;
        }
      } else {
        this.contentUrl = this.publication.url;
        this.downloadUrl = this.publication.downloadUrl;
        this.pageIndex = parseInt(this.publication.page ?? -1);
      }
    },
    changePageIndex(diff: number): void {
      this.imageLoading = true;
      this.pageIndex += diff;

      if (this.isFlip || this.isOfJournal) {
        const token = vInfo.flipPagination[this.pageIndex.toString()];
        if (!token) {
          let key = "visualization.last_page";
          if (diff > 0) {
            this.nextEnabled = false;
            this.pageIndex = this.pageIndex - 1;
          } else {
            this.previousEnabled = false;
            key = "visualization.first_page";
            this.pageIndex = this.pageIndex + 1;
          }
          this.$emit("alert", this.$t(key).toString());
          this.imageLoading = false;
          return;
        }

        searchApi.getFlipPages(this.publication.id, token).then((urls) => {
          this.downloadUrl = urls.downloadUrl;
          this.contentUrl = urls.url;
        });
        return;
      }

      if (this.publication.imagens) {
        this.pageIndex = this.pageIndex == -1 ? 0 : this.pageIndex;
        this.image = this.publication.imagens[this.pageIndex];
        this.contentUrl = this.image.url;
      } else {
        this.image = {} as Image;
      }
    },
    changeMediaIndex(adjacentPublicationDirection: string): void {
      this.$store.commit("visualization/setFullContentLoading", true);
      searchApi
        .getNextOrPreviousDownloadUrl(
          this.publication.id,
          adjacentPublicationDirection
        )
        .then((adjacentPublication) => {
          if (!adjacentPublication) {
            let key = "visualization.last_news";
            if (adjacentPublicationDirection == "next") {
              this.nextEnabled = false;
              this.pageIndex = this.pageIndex - 1;
            } else {
              this.previousEnabled = false;
              key = "visualization.first_news";
              this.pageIndex = this.pageIndex + 1;
            }
            this.$emit("alert", this.$t(key).toString());
            return;
          }
          this.$store.commit(
            "visualization/setPublication",
            adjacentPublication
          );
        })
        .catch((e) => console.error(e))
        .finally(() =>
          this.$store.commit("visualization/setFullContentLoading", false)
        );
    },
    onDownload(url: string): void {
      this.$props.downloading = true;
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let pathUrl = new URL(url).pathname;
          let filename = pathUrl.substring(pathUrl.lastIndexOf("/") + 1);
          link.download = filename;
          link.click();
        })
        .catch(console.error)
        .finally(() => {
          this.$props.downloading = false;
        });
    },
  },
});
